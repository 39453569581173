/* istanbul ignore file */

import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { HOUR, locale } from '@churchms/shared';
import { blog } from '../queryKeys';
import useFetchWithJWT from '../useFetchWithJWT';
import { useTokenIsValid } from '..';

export const config = {
  staleTime: 12 * HOUR,
};

export const useFetchBlog = () => {
  const fetchWithJWT = useFetchWithJWT();

  const fetchBlog = useCallback(() => {
    return fetchWithJWT('/api/v1/rss/posts', {}, locale.HTTP.ERROR.GET_RSS);
  }, [fetchWithJWT]);

  return fetchBlog;
};

const useBlog = () => {
  const fetchBlog = useFetchBlog();
  const isValid = useTokenIsValid();

  return useQuery({
    queryKey: blog(),
    queryFn: fetchBlog,
    enabled: isValid(),
    ...config,
  });
};

export default useBlog;
