import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { blog } from '../queryKeys';
import { useFetchBlog, config } from '../query/useBlog';
import useTokenIsValid from '../useTokenIsValid';

const usePrefetchBlog = () => {
  const queryClient = useQueryClient();

  const fetchBlog = useFetchBlog();
  const isValid = useTokenIsValid();

  return useCallback(async () => {
    if (isValid()) {
      await queryClient.prefetchQuery({
        queryKey: blog(),
        queryFn: fetchBlog,
        ...config,
      });
    }
  }, [fetchBlog, queryClient, isValid]);
};

export default usePrefetchBlog;
